<nav class="navbar navbar-expand navbar-dark bg-primary" [ngClass]="{'bg-secondary-alt': selfHosted}">
    <div class="container">
        <a class="navbar-brand" routerLink="/" appA11yTitle="{{'pageTitle' | i18n : 'Bitwarden'}}">
            <i class="fa fa-shield" aria-hidden="true"></i>
        </a>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/vault">{{'myVault' | i18n}}</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/sends">{{'send' | i18n}}</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/tools">{{'tools' | i18n}}</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/settings">{{'settings' | i18n}}</a>
                </li>
            </ul>
        </div>
        <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex">
            <li class="nav-item dropdown">
                <a class="nav-item nav-link dropdown-toggle" href="#" id="nav-profile" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-user-circle fa-lg" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="nav-profile">
                    <div class="dropdown-item-text d-flex align-items-center" *ngIf="name" appStopProp>
                        <app-avatar [data]="name" [email]="email" size="25" fontSize="14" [circle]="true"></app-avatar>
                        <div class="ml-2 overflow-hidden">
                            <span>{{'loggedInAs' | i18n}}</span>
                            <small class="text-muted">{{name}}</small>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" routerLink="/settings/account">
                        <i class="fa fa-fw fa-user" aria-hidden="true"></i>
                        {{'myAccount' | i18n}}
                    </a>
                    <a class="dropdown-item" href="https://onne.nl" target="_blank" rel="noopener">
                        <i class="fa fa-fw fa-question-circle" aria-hidden="true"></i>
                        {{'getHelp' | i18n}}
                    </a>
                    <a class="dropdown-item" href="https://bitwarden.com/download/" target="_blank" rel="noopener">
                        <i class="fa fa-fw fa-download" aria-hidden="true"></i>
                        {{'getApps' | i18n}}
                    </a>
                    <div class="dropdown-divider"></div>
                    <button type="button" class="dropdown-item" (click)="lock()">
                        <i class="fa fa-fw fa-lock" aria-hidden="true"></i>
                        {{'lockNow' | i18n}}
                    </button>
                    <button type="button" class="dropdown-item" (click)="logOut()">
                        <i class="fa fa-fw fa-sign-out" aria-hidden="true"></i>
                        {{'logOut' | i18n}}
                    </button>
                </div>
            </li>
        </ul>
    </div>
</nav>
